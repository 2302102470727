import theme, { isDark } from 'theme';

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  okButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.buttonBackgroundColor,
    fontSize: theme.typography.fontSize * 1.15,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.buttonBackgroundColorHover,
    },
  },
  declineButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[400],
    color: '#000000',
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  container: {
    position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '20px 15px',
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
    zIndex: 100,
    backgroundColor: isDark() ? '#212121' : '#FFF',
    borderColor: isDark() ? '#333333' : '#F5F5F5',
    borderStyle: 'solid',
    borderWidth: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      width: '96%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '94%',
    },
    [theme.breakpoints.down('md')]: {
      width: '92%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      marginBottom: theme.spacing(0.5),
      borderWidth: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '96%',
      marginBottom: theme.spacing(0.4),
      borderWidth: theme.spacing(0.5),
    },
  },
  wideContainer: {
    position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '96%',
    padding: '20px 15px',
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
    zIndex: 100,
    backgroundColor: isDark() ? '#212121' : '#FFF',
    borderColor: isDark() ? '#333333' : '#F5F5F5',
    borderStyle: 'solid',
    borderWidth: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      width: '94%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '98%',
      borderWidth: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '96%',
    },
  },
  copy: {
    paddingTop: 0,
    marginTop: 0,
    paddingLeft: theme.spacing(0.5),
  },
  copy2: {
    paddingTop: 0,
    marginTop: 0,
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});
