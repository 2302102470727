import React from 'react';
import loadable from '@loadable/component';
import { bool } from 'prop-types';
import { Link } from 'gatsby';
import { DEV_MODE } from 'gatsby-env-variables';
import { COOKIE_POLICY } from 'constants/navigation';

import useStyles from './styles';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const CookieConsent = loadable(() => import('react-cookie-consent'));

const TotalCookieConsent = ({ wideScreen = false }) => {
  const styles = useStyles();

  return (
    <CookieConsent
      disableStyles={true}
      onAccept={async () => {
        const getCookieConsentService = await import('services/cookie-consent-service');
        const { cookieConsent } = getCookieConsentService.default();
        cookieConsent(true);
      }}
      buttonText="Ok"
      declineButtonText="Only essential"
      onDecline={async () => {
        const getCookieConsentService = await import('services/cookie-consent-service');
        const { cookieConsent } = getCookieConsentService.default();
        cookieConsent(true);
      }}
      location="bottom"
      flipButtons
      enableDeclineButton
      containerClasses={wideScreen ? styles.wideContainer : styles.container}
      buttonClasses={styles.okButton}
      declineButtonClasses={styles.declineButton}
      cookieSecurity={!DEV_MODE}
      sameSite={DEV_MODE ? 'lax' : 'strict'}
  >
    <Hidden smUp>
      <Typography variant="body2" className={styles.copy2}>
        {' This website uses cookies to provide functionality. For more information please see our '}
        <Link to="/cookie-policy">Cookie Policy</Link>
      </Typography>
      </Hidden>
      <Hidden xsDown>
      <Typography variant="subtitle1" className={styles.copy}>
        This website uses cookies to provide functionality.
      </Typography>
      <Typography variant="body2" className={styles.copy2}>
        {'For more information please see our '}
        <Link to={COOKIE_POLICY}>Cookie Policy</Link>
      </Typography>
    </Hidden>
  </CookieConsent>
  );
};

TotalCookieConsent.propTypes = { wideScreen: bool };

export default TotalCookieConsent;
